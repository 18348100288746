'use client';

import { useEffect, useState } from 'react';

export const ReturnButton = () => {
	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		const headerObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					setIsVisible(!entry.isIntersecting);
				});
			},
			{
				root: null,
				threshold: 0.01
			}
		);

		const headerElement = document.querySelector('.header');
		if (headerElement) {
			headerObserver.observe(headerElement);
		}

		return () => {
			if (headerElement) {
				headerObserver.unobserve(headerElement);
			}
		};
	}, []);

	return (
		<div
			className="return"
			style={{ display: `${isVisible ? 'block' : 'none'}` }}>
			<a href="#begin" className="return__link">
				<svg>
					<use href="/svgs/labiela/sprite.svg#return"></use>
				</svg>
			</a>
		</div>
	);
};
